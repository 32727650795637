import { useEffect, useRef } from 'react';

function useSlider(slideRefs, thumbnailRefs = [], prevBtnRef, nextBtnRef) {
    const count = useRef(0);

    useEffect(() => {
        const updateSlider = () => {
            if (count.current >= slideRefs.length) {
                count.current = 0;
            }
            if (count.current < 0) {
                count.current = slideRefs.length - 1;
            }

            slideRefs.forEach((slideRef, index) => {
                if (slideRef.current) {
                    slideRef.current.style.transform = `translateX(-${count.current * 100}%)`;
                    slideRef.current.classList.toggle('displayed-img', index === count.current);
                }
            });

            (thumbnailRefs || []).forEach((thumbRef, index) => {
                if (thumbRef?.current) {
                    thumbRef.current.classList.toggle('thumbnail-focus', index === count.current);
                }
            });
        };

        const handleNext = () => {
            count.current++;
            updateSlider();
        };

        const handlePrev = () => {
            count.current--;
            updateSlider();
        };

        const handleThumbnailClick = (index) => {
            count.current = index;
            updateSlider();
        };

        if (nextBtnRef?.current) nextBtnRef.current.addEventListener('click', handleNext);
        if (prevBtnRef?.current) prevBtnRef.current.addEventListener('click', handlePrev);

        if (thumbnailRefs?.length) {
            thumbnailRefs.forEach((thumbRef, index) => {
                thumbRef?.current?.addEventListener('click', () => handleThumbnailClick(index));
            });
        }

        slideRefs.forEach((slideRef, index) => {
            if (slideRef.current) {
                slideRef.current.style.transform = `translateX(${index * 100}%)`;
                if (index === 0) {
                    slideRef.current.classList.add('displayed-img');
                }
            }
        });

        if (thumbnailRefs?.length && thumbnailRefs[0]?.current) {
            thumbnailRefs[0].current.classList.add('thumbnail-focus');
        }

        return () => {
            if (nextBtnRef?.current) nextBtnRef.current.removeEventListener('click', handleNext);
            if (prevBtnRef?.current) prevBtnRef.current.removeEventListener('click', handlePrev);

            if (thumbnailRefs?.length) {
                thumbnailRefs.forEach((thumbRef, index) => {
                    thumbRef?.current?.removeEventListener('click', () => handleThumbnailClick(index));
                });
            }
        };
    }, [slideRefs, thumbnailRefs, prevBtnRef, nextBtnRef]);
}

export default useSlider;
