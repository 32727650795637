import midLogo from './mid.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';


const Header = () => {
    const [nav, setNav] = useState("nav");

    const handleMenuClick = () => { 
        setNav("nav show");
    }
    const handleCloseBtn = () => {
        setNav("nav");
    }
    const handleNavShadow = () => {
        setNav("nav")
    }

    return (  
        <header className="">
            <div className="container">
                <div className="header-top flex">
                    <div className="header-menu-btn-wrap">
                        <button onClick={handleMenuClick} className="menu-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                                fill="#000000">
                                <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
                            </svg>
                        </button>
                    </div>
                    <Link to="/" className="header-logo-name-stack flex gap-small">
                        <div className="">
                            <img src={midLogo} alt="Logo" className="logo" />
                        </div>
                        <h1 className="ff-brand">Leyyin</h1>
                    </Link>
                    <div className="header-button-stack flex gap-small">
                        <button className="round-btn"><svg xmlns="http://www.w3.org/2000/svg" height="20px"
                                viewBox="0 -960 960 960" width="20px" fill="#000000">
                                <path
                                    d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                            </svg>
                        </button>
                        <button className="round-btn"><svg xmlns="http://www.w3.org/2000/svg" height="20px"
                                viewBox="0 -960 960 960" width="20px" fill="#000000">
                                <path
                                    d="M240-80q-33 0-56.5-23.5T160-160v-480q0-33 23.5-56.5T240-720h80q0-66 47-113t113-47q66 0 113 47t47 113h80q33 0 56.5 23.5T800-640v480q0 33-23.5 56.5T720-80H240Zm0-80h480v-480h-80v80q0 17-11.5 28.5T600-520q-17 0-28.5-11.5T560-560v-80H400v80q0 17-11.5 28.5T360-520q-17 0-28.5-11.5T320-560v-80h-80v480Zm160-560h160q0-33-23.5-56.5T480-800q-33 0-56.5 23.5T400-720ZM240-160v-480 480Z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <nav className={`flex ff-nav ${nav}`}>
                    <div className="nav-list-wrap">
                        <div className="nav-menu-close-btn-wrap">
                            <button onClick={handleCloseBtn} className="menu-close-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
                                    fill="#000000">
                                    <path
                                        d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                                </svg>
                            </button>
                        </div>
                        <ul className="flex">
                            <li><Link to="" className="active">T-shirt</Link></li>
                            <li><Link to="" className="">Sweatshirt</Link></li>
                            <li><Link to="" className="">Pantolon</Link></li>
                            <li><Link to="" className="">Şapka</Link></li>
                            <li><Link to="" className="">Diğer</Link></li>
                        </ul>
                    </div>
                    <div onClick={handleNavShadow} className="nav-menu-shadow"></div>
                </nav>
                
            </div>
        </header>
    );
}
export default Header;