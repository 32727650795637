import ProductList from "./ProductList";
import useFetch from "./useFetch";

const Main = () => {
    const { error, isPending, data } = useFetch('https://leyyin-r.vercel.app/api/productdata.json');
    const products = data ? data.products : null; // Access the 'products' array within the fetched data

    return (  
        <div className="main-page grid">
            {error && <div className="loading">{error}</div>}
            {isPending && <div className="loading">Loading...</div>}
            {products && products.length > 0 ? (
                <ProductList products={products} />
            ) : (
                !isPending && !error && <div className="loading">No products available.</div>
            )}
        </div>
    );
}

export default Main;
