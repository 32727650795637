import './App.css';
import TopSection from './TopSection';
import Header from './Header';
import Footer from './Footer';
import Main from './Main';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProductDetail from './ProductDetail';

function App() {
  return (
    <Router>
      <div className="App">
        <TopSection />
        <Header />
        <main className="main-page grid">
          <Routes>
            <Route exact path="/" element={<Main />}></Route>
            <Route path="/productdetail/:id" element={<ProductDetail />}></Route>
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
