const TopSection = () => {
    return (  
        <section className="top-section">
            <div className="container">
                <div className="top-sect-news-wrap">
                    <p className="top-sect-news">📦Kargo sadece 35.99 TL</p>
                </div>
            </div>
        </section>
    );
}
 
export default TopSection;