import { useParams } from "react-router-dom";
import useFetch from "./useFetch";
import { useState, useRef } from "react";
import useSlider from './useSlider';

const ProductDetail = () => {
    const { id } = useParams();
    const { data, error, isPending } = useFetch('https://leyyin-r.vercel.app/api/productdata.json');
    
    // Extract the product by filtering with the id from URL params
    const product = data ? data.products.find((item) => item.id === id) : null;

    const [modal, setModal] = useState("modal-product-img hide-modal");

    const handleModalBtn = () => {
        setModal("modal-product-img");
    };

    const handleModalCloseBtn = () => {
        setModal("modal-product-img hide-modal");
    };

    const handleModalShadow = () => {
        setModal("modal-product-img hide-modal");
    };

    const slideRefs = [useRef(), useRef(), useRef(), useRef()];
    const thumbnailRefs = [useRef(), useRef(), useRef(), useRef()]; 
    const prevBtnRef = useRef();
    const nextBtnRef = useRef();

    const modalSlideRefs = [useRef(), useRef(), useRef(), useRef()];
    const modalPrevBtnRef = useRef();
    const modalNextBtnRef = useRef();

    useSlider(slideRefs, thumbnailRefs, prevBtnRef, nextBtnRef);
    useSlider(modalSlideRefs, null, modalPrevBtnRef, modalNextBtnRef);

    return (
        <>
            {isPending && <div className="loading">Loading...</div>}
            {error && <div className="loading">{error}</div>}
            {product && (
                <div className="container flex-col">
                    <div className="catalog-main flex-col">
                        <div className="catalog-img-sect flex-col gap-mid">
                            <div className="catalog-img-wrap">
                                <div className="catalog-img-heart-wrap">
                                    <button className="round-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#e8eaed">
                                            <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="catalog-img-slider-btns flex">
                                    <button ref={prevBtnRef} className="left-btn round-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                                            <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
                                        </svg>
                                    </button>
                                    <button ref={nextBtnRef} className="right-btn round-btn">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                                            <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
                                        </svg>
                                    </button>
                                </div>
                                <button onClick={handleModalBtn} className="catalog-img-container flex">
                                    <img ref={slideRefs[0]} src={product.images.img1} alt={product.name} />
                                    <img ref={slideRefs[1]} src={product.images.img2} alt={product.name} />
                                    <img ref={slideRefs[2]} src={product.images.img3} alt={product.name} />
                                    <img ref={slideRefs[3]} src={product.images.img4} alt={product.name} />
                                </button>
                            </div>
                            <div className="catalog-thumbnail-img-btns flex">
                                <button ref={thumbnailRefs[0]} className="">
                                    <img src={product.images.img1} alt={product.name} />
                                </button>
                                <button ref={thumbnailRefs[1]} className="">
                                    <img src={product.images.img2} alt={product.name} />
                                </button>
                                <button ref={thumbnailRefs[2]} className="">
                                    <img src={product.images.img3} alt={product.name} />
                                </button>
                                <button ref={thumbnailRefs[3]} className="">
                                    <img src={product.images.img4} alt={product.name} />
                                </button>
                            </div>
                        </div>
                        <div className="catalog-info-sect flex-col gap-mid">
                            <div className="catalog-title-wrap">
                                <h2 className="catalog-title">{product.name}</h2>
                            </div>
                            <div className="flex-col gap-mid">
                                <div className="catalog-price-wrap flex gap-small">
                                    <p className="product-price">{product.price}</p>
                                    <p className="product-old-price">{product.oldPrice}</p>
                                </div>
                                <div className="catalog-size-wrap flex-col gap-mid">
                                    <p>Renk:</p>
                                    <div className="flex gap-small">
                                        <button className="round-btn"></button>
                                        <button className="round-btn"></button>
                                        <button className="round-btn"></button>
                                        <button className="round-btn"></button>
                                    </div>
                                </div>
                                <div className="catalog-size-wrap flex-col gap-mid">
                                    <p>Beden:</p>
                                    <div className="flex gap-small">
                                        <button className="round-btn">S</button>
                                        <button className="round-btn">M</button>
                                        <button className="round-btn">L</button>
                                        <button className="round-btn">XL</button>
                                    </div>
                                </div>
                                <div className="catalog-btn-wrap">
                                    <button className="product-main-btn">BUY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="catalog-descr-wrap flex-col">
                        <div className="catalog-descr">
                            <p>
                                <span>Açıklama:</span> {product.explanation}
                            </p>
                        </div>
                        <div className="catalog-descr-tags flex gap-small">
                            <div className="flex gap-small">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#e8eaed">
                                        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                                    </svg>
                                </div>
                                100% Cotton
                            </div>
                            <div className="flex gap-small">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#e8eaed">
                                        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                                    </svg>
                                </div>
                                Soft
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={`${modal} grid`}>
                <div onClick={handleModalShadow} className="modal-shadow"></div>
                <div className="modal-container flex-col gap-mid">
                    <div className="modal-close-btn-wrap flex">
                        <button className="modal-close-btn round-btn" onClick={handleModalCloseBtn}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000000">
                                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-img-wrap">
                        <div className="catalog-img-slider-btns flex">
                            <button ref={modalPrevBtnRef} className="modal-left-btn round-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                                    <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
                                </svg>
                            </button>
                            <button ref={modalNextBtnRef} className="modal-right-btn round-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                                    <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
                                </svg>
                            </button>
                        </div>
                        {isPending && <div className="loading">Loading...</div>}
                        {error && <div className="loading">{error}</div>}
                        {product && (
                            <div className="modal-img-container flex">
                                <img ref={modalSlideRefs[0]} src={product.images.img1} alt={product.name} />
                                <img ref={modalSlideRefs[1]} src={product.images.img2} alt={product.name} />
                                <img ref={modalSlideRefs[2]} src={product.images.img3} alt={product.name} />
                                <img ref={modalSlideRefs[3]} src={product.images.img4} alt={product.name} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetail;
